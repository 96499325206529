import React, {useEffect, useState} from 'react';
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby"
import {Container} from "../styles/global";
import {faMoneyBillWave, faShapes, faUser, faCube} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Help = ({location}) => {
    const menuQuery = useStaticQuery(
        graphql`
          query  {
          allContentfulKnowledgeHubArticle {
            group(field: entry) {
              fieldValue
              nodes {
                slug
                title
                order
              }
            }
          }
        }
        `
    )
    const links = [
        {
            fieldValue: "Getting Started",
            description: "Learn how to get started with using Jinolo.",
            slug: "upload-a-file",
            image: faShapes
        },
        {
            fieldValue: "Account",
            description: "Manage your Jinolo account with ease.",
            slug: "sign-up",
            image: faUser
        },
        {
            fieldValue: "Viewer",
            description: "Explore our 3d and 2d viewer.",
            slug: "view-file",
            image: faCube
        },
    ]
    const metadata = {
        title: "Jinolo Knowledge Base",
        description: "Get help with Jinolo to get started, upload and share files."

    }


    return (
        <Layout location={location}>
            <SEO
                title={metadata.title}
                description={metadata.description}

            />
            <Container>
                <BlogGroup>

                    <div className="font-grotesk-bold text-navy text-center text-5xl mb-6">{metadata.title}</div>
                    <p className="mb-10 mt-3 font-grotesk-medium text-center">{metadata.description}</p>

                    <div className="flex flex-col justify-center text-navy px-9">
                        {
                            links.map((entry) => (
                                <div className="flex flex-wrap justify-center border-2 border-accent m-4 rounded-3xl p-3">
                                    <div className=" flex-col w-full md:w-1/2 p-5">
                                        <div className="text-2xl text-left font-grotesk-bold mb-3">
                                            {entry.fieldValue}
                                        </div>
                                        <div className="mb-8">{entry.description}</div>
                                        {/*<div className="bg-primary text-navy font-grotesk-bold text-lg">*/}
                                        <Link to={`/help/${entry.slug}/`}
                                            // className="text-gray-800 hover:text-gray-500"
                                              style={{textDecoration: 'none', color: 'inherit'}}>
                                            <Button>
                                                Learn More
                                            </Button>


                                        </Link>
                                        {/*</div>*/}
                                    </div>
                                    <div className="w-full md:w-1/2 flex justify-center py-10 text-primary">
                                        <FontAwesomeIcon icon={entry.image} size="7x" />
                                    </div>
                                </div>
                            ))

                        }

                    </div>
                    {/*</div>*/}
                </BlogGroup>
            </Container>

        </Layout>
    )
}
export default Help

const BlogGroup = styled.div`

  padding: 160px 20px 10px 20px;
  position: relative;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  // margin: 0;
  // padding: 20px;

  h1 {
    margin: 0 0 24px;
    color: ${props => props.theme.color.navy};
  }

  h2 {
    margin-bottom: 24px;
    color: ${props => props.theme.color.navy};
  }

  p {
    color: ${props => props.theme.color.navy};
    margin-bottom: 24px;
  }
`
const Title = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #121640;
  margin-bottom: 44px;
  text-align: center;
  margin-top: 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    font-size: 32px;
  }
`

const Button = styled.a`
  color: white;
  text-transform: uppercase;
  background-color: #121640;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  max-width: 220px;
  //margin: 22px auto 0px;
  padding: 14px 10px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
`
